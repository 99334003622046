import ConsumerSignUpForm from "features/auth/components/consumerSignupForm";
import LoginPageLayout from "layouts/consumerPortal/loginPageLayout";

function SignUpPage() {
  return (
    <LoginPageLayout>
      <ConsumerSignUpForm />
    </LoginPageLayout>
  );
}

export default SignUpPage;
