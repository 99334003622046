import { CreditCardOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Form, Input, Modal, Radio, Space } from "antd";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledInput = styled(Input)`
  width: 250px;
`;

const StyledFlex = styled(Flex)`
  margin-left: 20px;
  margin-top: 8px;
`;

export default function AddPaymentMethodModal({ open, onCancel }) {
  const handleAddPaymentMethod = () => {};
  const { data: constants = {} } = useFetchBackendConstantsQuery();
  const [form] = Form.useForm();
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState("");
  return (
    <Modal
      title="New Payment Method"
      onCancel={onCancel}
      open={open}
      width={700}
      footer={[
        <Button key="submit" type="primary" onClick={handleAddPaymentMethod}>
          Add Payment Method
        </Button>,
      ]}
    >
      <Divider />
      <Form labelAlign="left" layout="vertical" form={form}>
        {/* <h5>Select Method</h5> */}
        <Form.Item name="paymentMethod" rules={[{ required: true }]}>
          <Radio.Group
            onChange={(e) => {
              setCurrentPaymentMethod(e.target.value);
            }}
          >
            <Space direction="vertical">
              <Flex
                vertical
                style={{
                  border: "1px solid #f0f0f0",
                  padding: "16px",
                  width: "616px",
                }}
              >
                <Radio
                  style={{
                    fontWeight: currentPaymentMethod === "creditCard" ? "bold" : "normal",
                  }}
                  value="creditCard"
                >
                  New Credit Card or Debit Card
                </Radio>
                {currentPaymentMethod === "creditCard" && (
                  <StyledFlex vertical>
                    <Form.Item
                      label="Card Number"
                      name="creditCardNumber"
                      rules={[{ required: true }]}
                    >
                      <Input
                        width="100%"
                        prefix={<CreditCardOutlined />}
                        placeholder="0000 0000 0000 0000"
                      />
                    </Form.Item>
                    <Space size="large">
                      <Form.Item
                        label="Expiry Date"
                        name="expirationDate"
                        rules={[{ required: true }]}
                      >
                        <StyledInput placeholder="MM/YY" />
                      </Form.Item>
                      <Form.Item label="Security Code" name="cvv" rules={[{ required: true }]}>
                        <StyledInput placeholder="CVV/CVC" />
                      </Form.Item>
                    </Space>
                    <Form.Item label="Cardholder Name" name="name" rules={[{ required: true }]}>
                      <StyledInput placeholder="Cardholder Name" />
                    </Form.Item>
                  </StyledFlex>
                )}
              </Flex>

              <Space direction="vertical">
                <Flex
                  vertical
                  style={{
                    border: "1px solid #f0f0f0",
                    width: "616px",
                    padding: "16px",
                  }}
                >
                  <Radio
                    value="bankAccount"
                    style={{
                      fontWeight: currentPaymentMethod === "bankAccount" ? "bold" : "normal",
                    }}
                  >
                    Bank Account ACH
                  </Radio>

                  {currentPaymentMethod === "bankAccount" && (
                    <StyledFlex vertical>
                      <Space size="large">
                        <Form.Item
                          label="Bank Name"
                          name="routingNumber"
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Bank Name" />
                        </Form.Item>
                        <Form.Item
                          label="Routing Number"
                          name="routingNumber"
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Routing Number" />
                        </Form.Item>
                      </Space>
                      <Space size="large">
                        <Form.Item
                          label="Account Number"
                          name="accountNumber"
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Account Number" />
                        </Form.Item>
                        <Form.Item
                          label="Confirm Account Number"
                          name="accountNumber"
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Account Number" />
                        </Form.Item>
                      </Space>
                    </StyledFlex>
                  )}
                </Flex>
              </Space>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}
