import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Col, Descriptions, Popover, Row, Table } from "antd";
import { formatCurrency } from "common/utils";
import currency from "currency.js";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledDiv = styled.div`
  margin-top: 24px;
  margin-left: 16px;
  & span {
    margin-left: 12px;
  }
`;
const StyledInfoValue = styled.div`
  margin-left: 4px;
  margin-right: 4px;
`;
function DashboardPage() {
  const columns = [
    { title: "Creditor", dataIndex: "creditor", key: "creditor" },
    { title: "Account Number", dataIndex: "account", key: "account" },
    {
      title: "Current Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => {
        const balanceItems = [
          {
            key: "principal",
            label: "Principal",
            value: formatCurrency(text),
          },
          {
            key: "originalInterest",
            label: `Interest (${currency(0.2, { precision: 4 }).multiply(100).value}%)`,
            value: formatCurrency(record.originalInterest),
          },
          {
            key: "totalFees",
            label: "Fees",
            value: formatCurrency(text),
          },
          {
            key: "originalTotal",
            label: <strong>Total</strong>,
            value: <strong>{formatCurrency(text)}</strong>,
          },
        ];
        const totalBalanceContent = (
          <Col>
            <Descriptions bordered column={1} size="small">
              {balanceItems.map((item) => (
                <Descriptions.Item key={item.key} label={item.label}>
                  {item.value}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Col>
        );
        return (
          <Row align="middle">
            <StyledInfoValue>{formatCurrency(text)}</StyledInfoValue>
            <Popover
              content={totalBalanceContent}
              title="Current Balance Breakdown"
              trigger="click"
              placement="right"
            >
              <InfoCircleOutlined />
            </Popover>
          </Row>
        );
      },
    },
    { title: "Due Date", dataIndex: "dueDate", key: "dueDate" },
  ];

  return (
    <>
      <h3>Accounts Summary</h3>
      <Card>
        <Table
          pagination={false}
          dataSource={[
            {
              key: "1",
              creditor: "Chase Bank",
              creditorNumber: "123456789012",
              balance: "$40",
              account: "123456789",
              dueDate: "12/12/2021",
            },
            {
              key: "2",
              creditor: "Bank of America",
              creditorNumber: "123456789012",
              lastPaymetDate: "12/12/2021",
              balance: "$32",
              account: "123456789",
              dueDate: "12/12/2021",
            },
            {
              key: "3",
              creditor: "Wells Fargo",
              creditorNumber: "123456789012",
              balance: "$13",
              account: "123456789",
              dueDate: "12/12/2021",
            },
          ]}
          columns={columns}
        />
        <StyledDiv>
          <strong>Current Balance</strong>
          <span>$72.00</span>
          <div style={{ fontStyle: "italic" }}>
            For questions regarding your account(s), please visit the{" "}
            <Link to="/contact">Contact Us</Link> page to find your agency's contact information.
          </div>
        </StyledDiv>
      </Card>
    </>
  );
}

export default DashboardPage;
