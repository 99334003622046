import { Card, Steps } from "antd";
import Content from "layouts/consumerPortal/content";

function MakePaymentPage() {
  return (
    <Content>
      <Card>
        <h3 style={{ marginBottom: 8, marginTop: 0 }}>Make a Payment</h3>
        <Steps
          direction="vertical"
          current={1}
          items={[
            {
              title: "Select an Account",
            },
            {
              title: "Select Payment Method",
            },
            {
              title: "Schedule Payment",
            },
          ]}
        />
      </Card>
    </Content>
  );
}

export default MakePaymentPage;
