import { HomeTwoTone, MailTwoTone, PhoneTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Space, Tag } from "antd";
import styled from "styled-components";

const StyledDiv = styled.div`
  color: gray;
`;

function ProfilePage() {
  return (
    <Flex vertical align="center" justify="center">
      <h2>John Smith</h2>
      <Card
        title={
          <Flex
            style={{
              marginBottom: 12,
            }}
            align="center"
            justify="space-between"
          >
            <div>
              <h4 style={{ marginBottom: -5 }}>Phone Numbers</h4>
              <span style={{ fontSize: 12, fontWeight: "normal", color: "grey" }}>
                Make sure to keep your primary phone number up-to-date.
              </span>
            </div>
            <Button style={{ marginTop: 12 }} shape="round" type="primary" icon={<PlusOutlined />}>
              Add Phone Number
            </Button>
          </Flex>
        }
        style={{ width: 800, marginBottom: 12 }}
      >
        <Space style={{ marginBottom: 8 }}>
          <PhoneTwoTone twoToneColor="#52c41a" />
          <div>
            (123)-456-7890 <Tag>PRIMARY</Tag>
          </div>
        </Space>
        <br />
        <Space>
          <PhoneTwoTone twoToneColor="#52c41a" />
          <div>(456)-789-0123</div>
        </Space>
      </Card>
      <Card
        title={
          <Flex
            style={{
              marginBottom: 12,
            }}
            align="center"
            justify="space-between"
          >
            <div>
              <h4 style={{ marginBottom: -5 }}>Email Addresses</h4>
              <span style={{ fontSize: 12, fontWeight: "normal", color: "grey" }}>
                Make sure to keep your primary email address up-to-date.
              </span>
            </div>
            <Button style={{ marginTop: 12 }} shape="round" type="primary" icon={<PlusOutlined />}>
              Add Email
            </Button>
          </Flex>
        }
        style={{ width: 800, marginBottom: 12 }}
      >
        <Space style={{ marginBottom: 8 }}>
          <MailTwoTone twoToneColor="blue" />
          <div>
            test@gmail.com <Tag>PRIMARY</Tag>
          </div>
        </Space>
        <br />
        <Space>
          <MailTwoTone twoToneColor="blue" />
          <div>123@gmail.com</div>
        </Space>
      </Card>
      <Card
        title={
          <Flex
            style={{
              marginBottom: 12,
            }}
            align="center"
            justify="space-between"
          >
            <div>
              <h4 style={{ marginBottom: -5 }}>Addresses</h4>
              <span style={{ fontSize: 12, fontWeight: "normal", color: "grey" }}>
                Make sure to keep your primary address up-to-date.
              </span>
            </div>
            <Button style={{ marginTop: 12 }} shape="round" type="primary" icon={<PlusOutlined />}>
              Add Address
            </Button>
          </Flex>
        }
        style={{ width: 800, marginBottom: 12 }}
      >
        <Space style={{ marginBottom: 8 }}>
          <HomeTwoTone twoToneColor="purple" />
          <div>
            123 Main St <Tag>PRIMARY</Tag>
          </div>
        </Space>
        <br />
        <Space>
          <HomeTwoTone twoToneColor="purple" />
          <div>Anytown, USA 12345</div>
        </Space>
      </Card>
    </Flex>
  );
}

export default ProfilePage;
