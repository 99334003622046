import { Col, Layout } from "antd";
import { useWhiteLabel } from "providers/whiteLabelProvider";
import styled from "styled-components";
import CompanyLogo from "assets/aktos-logo-366x340-nowhitespace.png";

const StyledCol = styled(Col)`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 48px;
  background: #fff;
  border-radius: 10px;
`;

const StyledImg = styled.img`
  margin-left: 20px;
  margin-top: 16px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 90px);
  position: absolute;
  background: #f7f7f7;
  padding-bottom: 24px;
`;

const StyledHeader = styled(Layout.Header)`
  min-width: 470px;
  z-index: 10;
  border-bottom: 1px solid #d9d9d9;
  background: #fff;
  @media only screen and (max-width: 470px) {
    display: none;
  }
  &&& {
    padding-inline: 0;
  }
`;

const StyledDiv = styled.div`
  margin-top: 28px;
  text-align: center;
  & a {
    color: #0098ed;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    font-size: 14px;
  }
`;

function LoginPageLayout({ children }) {
  const { isWhiteLabeled, logo } = useWhiteLabel();

  return (
    <>
      <StyledHeader>
        <StyledImg alt="" src={CompanyLogo} width={32} height={32} />
      </StyledHeader>
      {children}
    </>
  );
}

export default LoginPageLayout;
