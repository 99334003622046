import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Popconfirm, Table, message } from "antd";
import config from "app/envConfig";
import { capitalizeFirstLetter } from "common/utils";
import UploadDocumentModal from "features/documentsTable/consumerPortal/components/uploadDocumentModal";
import {
  useDeleteConsumerPortalDebtorDocumentMutation,
  useGetConsumerPortalDebtorDocumentsQuery,
} from "features/documentsTable/consumerPortal/documentsAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledButton = styled(Button)``;

const StyledTable = styled(Table)`
  & .ant-table-cell:not(th) {
    padding: 0px 8px !important;
  }
`;

function DocumentsTable() {
  const [isUploadDocumentModalVisible, setUploadDocumentModalVisible] = useState(false);
  const { data: documents, isLoading: isDocumentsLoading } =
    useGetConsumerPortalDebtorDocumentsQuery();
  const [deleteConsumerPortalDocument] = useDeleteConsumerPortalDebtorDocumentMutation();

  const handleDelete = async (payload) => {
    const result = await deleteConsumerPortalDocument(payload);
    if ("data" in result) {
      message.success("Document removed successfully!");
    }
  };

  const onUploadDocumentOk = () => {
    setUploadDocumentModalVisible(false);
  };

  const onUploadDocumentCancel = () => {
    setUploadDocumentModalVisible(false);
  };

  const columns = [
    {
      title: "Upload Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => new Date(record.createdAt).toLocaleDateString(),
      sorter: {
        compare: (a, b) => {
          const dateA = a.createdAt;
          const dateB = b.createdAt;
          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        const tokens = text.split("_").map((val) => {
          return capitalizeFirstLetter(val);
        });
        const prettifiedText = tokens.join(" ");
        return <div>{prettifiedText}</div>;
      },
      sorter: (a, b) => {
        return a.type.toLowerCase() > b.type.toLowerCase();
      },
    },
    {
      title: "File Name",
      dataIndex: "filename",
      key: "filename",
      render: (text, record) => {
        return record.file ? (
          <a target="_blank" href={new URL(record.file, config.apiBaseUrl).href} rel="noreferrer">
            {text}
          </a>
        ) : (
          text
        );
      },
    },
    {
      title: "External URL",
      dataIndex: "externalUrl",
      key: "externalUrl",
      width: 200,
      render: (externalUrl) => {
        return externalUrl ? (
          <a target="_blank" href={externalUrl} rel="noreferrer">
            {externalUrl}
          </a>
        ) : null;
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete this document?"
            onConfirm={() =>
              handleDelete({
                documentId: record.id,
                type: record.type,
              })
            }
          >
            <DeleteOutlined key="delete" />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <>
      <Flex style={{ marginBottom: 12, marginTop: 12 }} align="center">
        <h3 style={{ marginTop: 0, marginBottom: 0 }}>Documents</h3>
        <StyledButton
          type="link"
          onClick={() => setUploadDocumentModalVisible(true)}
          icon={<PlusOutlined />}
        >
          Add Document
        </StyledButton>
      </Flex>
      <StyledTable
        bordered
        loading={isDocumentsLoading}
        style={{ cursor: "pointer" }}
        // @ts-ignore
        columns={columns}
        dataSource={documents}
        scroll={{ x: "max-content" }}
      />
      <UploadDocumentModal
        title="Add Document"
        open={isUploadDocumentModalVisible}
        onOk={onUploadDocumentOk}
        onCancel={onUploadDocumentCancel}
      />
    </>
  );
}

export default DocumentsTable;
