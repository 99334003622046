import { HomeOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Card, Empty, Flex, Space, Typography } from "antd";

const { Link } = Typography;
function ContactUsPage() {
  return (
    // Hacking the AntD Empty component to make use of the stock image
    <Empty
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
      description={
        <Flex vertical justify="center" align="center">
          <h1 style={{ marginBottom: 0, marginTop: 0, fontWeight: "normal" }}>Contact Us</h1>
          <p style={{ color: "gray" }}>
            If you have any questions or concerns, please feel free to contact your agency using the
            information below.
          </p>
          <br />
          <Card style={{ width: 800, position: "static" }}>
            <Flex vertical justify="center" align="center">
              <Link href="https://www.google.com" target="_blank" rel="noopener noreferrer">
                <h5 style={{ fontWeight: "normal", marginTop: 0, color: "#1890ff" }}>
                  Test Agency
                </h5>
              </Link>
              <Flex vertical>
                <Space size="middle">
                  <HomeOutlined />
                  <span>123 Main St Anytown, USA 12345</span>
                </Space>
                <Space size="middle">
                  <PhoneOutlined />
                  <span>(123)-456-7890</span>
                </Space>
                <Space size="middle">
                  <MailOutlined />
                  <span>agency@gmail.com</span>
                </Space>
              </Flex>
            </Flex>
          </Card>
        </Flex>
      }
    />
  );
}

export default ContactUsPage;
