import { Button, Card, Space, Table } from "antd";

export default function UpcomingPaymentsPage() {
  return (
    <>
      <h3>Upcoming Payments</h3>
      <Card>
        <Table
          scroll={{ x: "max-content" }}
          dataSource={[
            {
              key: "1",
              date: "12/12/2024",
              id: "238940233456",
              method: "Credit Card ****3645",
              amount: "$36.00",
            },
            {
              key: "2",
              date: "12/12/2024",
              id: "38923483456",
              method: "Bank Account ****3645",
              amount: "$54.00",
            },
          ]}
          columns={[
            { title: "Scheduled Date", dataIndex: "date", key: "date" },
            { title: "Account ID(s)", dataIndex: "id", key: "id" },
            { title: "Payment Method", dataIndex: "method", key: "method" },
            { title: "Amount", dataIndex: "amount", key: "amount" },
            {
              title: "",
              dataIndex: "action",
              key: "action",
              render: () => (
                <Space size="middle">
                  <Button type="link">Cancel</Button>
                </Space>
              ),
            },
          ]}
        />
      </Card>
      <br />
    </>
  );
}
