import * as Sentry from "@sentry/react";
import { setupStore } from "common/redux/store";
import { authSlice, loadAuthSlice } from "features/auth/authSlice";
import { ContactUsPage } from "pages/consumerPortal/contactUs";
import { DashboardPage } from "pages/consumerPortal/dashboard";
import { DocumentsPage } from "pages/consumerPortal/documents";
import { NotFoundPage } from "pages/consumerPortal/errorPages";
import { Login } from "pages/consumerPortal/login";
import { Logout } from "pages/consumerPortal/logout";
import { Signup } from "pages/consumerPortal/signup";
import { GlobalErrorPage } from "pages/errorPages";
import config from "portal/envConfig";
import { ReduxStoreProvider, WhiteLabelProvider } from "providers";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";
import "./App.css";
import { ChangePassword } from "pages/consumerPortal/changePassword";
import { MakePaymentPage } from "pages/consumerPortal/makePayment";
import { PaymentHistoryPage } from "pages/consumerPortal/paymentHistory";
import { ProfilePage } from "pages/consumerPortal/profile";
import { PaymentMethodsPage } from "pages/consumerPortal/paymentMethods";
import { UpcomingPaymentsPage } from "pages/consumerPortal/upcomingPayments";
import PageLayout from "layouts/consumerPortal/pageLayout";

const store = setupStore({
  [authSlice.name]: loadAuthSlice(),
});

function App() {
  const isDevMode = config.aktosEnv !== "prod";
  const aktosTitle = isDevMode ? `Aktos (${config.aktosEnv})` : "Aktos";
  useDocumentTitle(aktosTitle);

  const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)([
    {
      path: "/",
      errorElement: <GlobalErrorPage />,
      element: <PageLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "logout", Component: Logout },
        { index: true, element: <Navigate to="/dashboard" replace /> },
        { path: "signup", element: <Signup /> },
        { path: "dashboard", element: <DashboardPage /> },
        { path: "payments/payment-history", element: <PaymentHistoryPage /> },
        { path: "payments/make-payment", element: <MakePaymentPage /> },
        { path: "payments/payment-methods", element: <PaymentMethodsPage /> },
        { path: "payments/upcoming-payments", element: <UpcomingPaymentsPage /> },
        { path: "documents", element: <DocumentsPage /> },
        { path: "contact", element: <ContactUsPage /> },
        { path: "profile", element: <ProfilePage /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "*", element: <NotFoundPage /> },
      ],
    },
  ]);

  return (
    <ReduxStoreProvider store={store}>
      <WhiteLabelProvider>
        <RouterProvider router={router} />
      </WhiteLabelProvider>
    </ReduxStoreProvider>
  );
}

export default Sentry.withProfiler(App);
