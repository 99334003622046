import { BankOutlined, CloseOutlined, CreditCardOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Flex } from "antd";
import AddPaymentMethodModal from "features/addPaymentMethod/addPaymentMethodModal";
import { useState } from "react";
import styled from "styled-components";

const StyledCard = styled(Card)`
  margin-right: 16px;
  margin-bottom: 16px;
  display: flex:
  flex-direction: column;
  &.ant-card {
    min-width: 400px !important;
  }
`;
export default function PaymentMethodsPage() {
  const onMenuClick = () => {
    console.log("menu clicked");
  };
  const [isModalVisible, setModalVisible] = useState(false);
  return (
    <>
      <h3 style={{ marginBottom: 16 }}>Payment Methods</h3>
      <StyledCard>
        <Flex justify="space-between" align="center">
          <Flex>
            <CreditCardOutlined
              style={{
                padding: "0px 16px",
                border: "1px solid #d9d9d9",
                fontSize: 24,
                marginRight: 8,
              }}
            />
            <div>
              <h3 style={{ marginBottom: 0, marginTop: 0 }}>VISA</h3>
              <div style={{ color: "gray" }}>Card ending in •••• 1234</div>
            </div>
          </Flex>
          <Button
            type="text"
            icon={
              <CloseOutlined
                style={{
                  color: "gray",
                  fontSize: 13,
                }}
              />
            }
          />
          {/* <Dropdown
            // @ts-ignore
            placement="leftTop"
            menu={{
              items: [
                {
                  key: "1",
                  label: "Make Primary",
                },
                {
                  key: "2",
                  label: "Edit",
                },
                {
                  key: "3",
                  label: "Delete",
                },
              ],
              onClick: onMenuClick,
            }}
            trigger={["click"]}
          >
            <Button type="text">
              <EllipsisOutlined
                style={{
                  fontSize: 24,
                }}
              />
            </Button>
          </Dropdown> */}
        </Flex>
      </StyledCard>
      <StyledCard>
        <Flex justify="space-between" align="center">
          <Flex>
            <BankOutlined
              style={{
                padding: "0px 16px",
                border: "1px solid #d9d9d9",
                fontSize: 24,
                marginRight: 8,
              }}
            />
            <div>
              <h3 style={{ marginBottom: 0, marginTop: 0 }}>Bank of America</h3>
              <div style={{ color: "gray" }}>Bank ending in •••• 5678</div>
            </div>
          </Flex>
          <Button
            type="text"
            icon={
              <CloseOutlined
                style={{
                  color: "gray",
                  fontSize: 13,
                }}
              />
            }
          />
          {/* <Dropdown
            // @ts-ignore
            placement="leftTop"
            menu={{
              items: [
                {
                  key: "1",
                  label: "Make Primary",
                },
                {
                  key: "2",
                  label: "Edit",
                },
                {
                  key: "3",
                  label: "Delete",
                },
              ],
              onClick: onMenuClick,
            }}
            trigger={["click"]}
          >
            <Button type="text">
              <EllipsisOutlined
                style={{
                  fontSize: 24,
                }}
              />
            </Button>
          </Dropdown> */}
        </Flex>
      </StyledCard>
      <Button
        style={{
          paddingTop: 12,
          paddingBottom: 12,
          backgroundColor: "#f5f5f5",
          height: "auto",
          width: "calc(100% - 16px)",
        }}
        type="dashed"
        block
        onClick={() => setModalVisible(true)}
        icon={<PlusOutlined />}
      >
        Add Payment Method
      </Button>
      {isModalVisible && (
        <AddPaymentMethodModal onCancel={() => setModalVisible(false)} open={isModalVisible} />
      )}
    </>
  );
}
