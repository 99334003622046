import ConsumerLoginForm from "features/auth/components/consumerLoginForm";
import LoginPageLayout from "layouts/consumerPortal/loginPageLayout";

function Login() {
  return (
    <LoginPageLayout>
      <ConsumerLoginForm />
    </LoginPageLayout>
  );
}

export default Login;
