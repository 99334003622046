import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Table } from "antd";

function PaymentHistoryPage() {
  return (
    <>
      <h3>Payment History</h3>
      <Card>
        <Flex justify="flex-end">
          <Button type="link" size="large" icon={<DownloadOutlined />} />
        </Flex>
        <Table
          scroll={{ x: "max-content" }}
          dataSource={[
            {
              key: "1",
              method: "Credit Card ****8296",
              accounts: [1234567890123456],
              coDebtor: "$24.00",
              amount: "$36.00",
              date: "12/12/2021",
            },
            {
              key: "2",
              method: "Bank Account ****3645",
              coDebtor: "$24.00",
              accounts: [1234567890123456, 2304823940283940],
              amount: "$54.00",
              date: "12/12/2021",
            },
          ]}
          columns={[
            { title: "Payment Method", dataIndex: "method", key: "date" },
            {
              title: "Account ID(s)",
              dataIndex: "accounts",
              key: "date",
              render: (text, record) => record.accounts.join(", "),
            },
            { title: "Date", dataIndex: "date", key: "date" },
            { title: "Payments from Codebtors", dataIndex: "coDebtor", key: "coDebtor" },
            { title: "Amount", dataIndex: "amount", key: "amount" },
          ]}
        />
      </Card>
    </>
  );
}

export default PaymentHistoryPage;
